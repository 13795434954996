<template>
    <hr :class="antlerClass()" />
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppDivider",
    props: {
        variant: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("divider", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.divider
    display: flex
    width: 100%
    height: 1px
    margin:
        top: $spacing-regular
        bottom: $spacing-regular

    background-color: $color-blue-lighter
    border: 0

.divider--vertical
    width: 1px
    height: auto
    margin: 0 $spacing-regular

    background-color: $color-blue-lighter
    border: 0

.divider--no-spacing
    margin: 0

.divider--no-spacing-top
    margin-top: 0

.divider--color-dark
    background-color: $color-blue-shade-light
</style>
