<template>
    <div class="ds__page ds__page--background">
        <DsSection>
            <DsHeader>
                <h1 class="title ds__title">
                    Planning
                </h1>
            </DsHeader>

            <AppScheduleFormItemGroup @addScheduleRow="addScheduleRow">
                <Draggable
                    v-model="formValues.scheduleRows"
                    :disabled="loading > 0"
                    handle=".button--draggable"
                    @change="changeSchedule"
                >
                    <AppScheduleFormItem
                        v-for="row in formValues.scheduleRows"
                        :key="`${row.index}-${row.id}`"
                        context="schedule"
                        :delete-item="row.delete"
                        :disabled="loading > 0"
                        :enable-end-time="
                            !lastNotDeleted ||
                                row.index === lastNotDeleted.index
                        "
                        :end-time.sync="row.endTime"
                        :form-errors="formErrors.scheduleRows.forms[row.index]"
                        :grades="
                            schedule.grade
                                ? [schedule.grade.grade]
                                : schedule.groupSchedule.group.grades
                        "
                        :notes.sync="row.notes"
                        :theme.sync="row.theme"
                        :purpose.sync="row.purpose"
                        :start-time="row.startTime"
                        :subject-description.sync="row.subjectDescription"
                        :subject-id.sync="row.subjectId"
                        :sub-subject-id.sync="row.subSubjectId"
                        :bundle-rows="row.bundleRows"
                        @update:deleteItem="
                            row.delete = $event;
                            updateEndTimes();
                        "
                        @update:startTime="
                            row.startTime = $event;
                            updateEndTimes();
                        "
                    />
                </Draggable>
            </AppScheduleFormItemGroup>

            <pre>{{ JSON.stringify(formValues.scheduleRows, null, 2) }}</pre>
        </DsSection>
    </div>
</template>

<script>
import AppScheduleFormItem from "@/components/AppScheduleFormItem.vue";
import AppScheduleFormItemGroup from "@/components/AppScheduleFormItemGroup.vue";
import Draggable from "vuedraggable";
import DsHeader from "@/designsystem/components/DsHeader";
import DsSection from "@/designsystem/components/DsSection";

export default {
    name: "DsScheduleUpdate",
    components: {
        Draggable,
        AppScheduleFormItemGroup,
        AppScheduleFormItem,
        DsHeader,
        DsSection
    },
    data() {
        return {
            loading: 0,
            formErrors: {
                scheduleRows: {
                    nonFormErrors: [],
                    forms: []
                }
            },
            emptyFormErrors: {
                scheduleRows: {
                    nonFormErrors: [],
                    forms: []
                }
            },
            emptyScheduleRow: {
                index: null,
                id: "",
                startTime: null,
                endTime: null,
                notes: "",
                theme: "",
                purpose: "",
                subjectId: "",
                subSubjectId: "",
                subjectDescription: "",
                delete: false
            },
            formValues: {
                scheduleRows: [
                    {
                        index: 0,
                        id: "U2NoZWR1bGVSb3c6MTUx",
                        startTime: "09:00",
                        endTime: "10:00",
                        notes: "notitie",
                        theme: "",
                        purpose: "",
                        subjectId: "U3ViamVjdDox",
                        subSubjectId: "U3ViamVjdDoxNA==",
                        subjectDescription: "",
                        delete: false
                    },
                    {
                        index: 1,
                        id: "U2NoZWR1bGVSb3c6MTUy",
                        startTime: "10:00",
                        endTime: "11:00",
                        notes: "",
                        theme: "",
                        purpose: "",
                        subjectId: null,
                        subSubjectId: null,
                        subjectDescription: "",
                        delete: false
                    },
                    {
                        index: 2,
                        id: "U2NoZWR1bGVSb3c6MTUz",
                        startTime: "11:00",
                        endTime: "12:00",
                        notes: "asdadaa",
                        theme: "thema",
                        purpose: "Doel",
                        subjectId: "U3ViamVjdDo1",
                        subSubjectId: "U3ViamVjdDoyNA==",
                        subjectDescription: "",
                        delete: false
                    },
                    {
                        index: 3,
                        id: "U2NoZWR1bGVSb3c6MTUz",
                        startTime: "11:00",
                        endTime: "12:00",
                        notes: "",
                        theme: "",
                        purpose: "",
                        subjectId: "U3ViamVjdDo1",
                        subSubjectId: "U3ViamVjdDoyNA==",
                        subjectDescription: "",
                        delete: false,
                        bundleRows: [
                            {
                                index: 4,
                                id: "bundle-1",
                                subjectId: "U3ViamVjdDox",
                                subSubjectId: "U3ViamVjdDoxNA==",
                                subjectDescription: "",
                                notes: "",
                                startTime: "11:00",
                                endTime: "12:00",
                                theme: "Dit is het thema",
                                purpose: "",
                                delete: false
                            },
                            {
                                index: 5,
                                id: "bundle-2",
                                subjectId: "U3ViamVjdDox",
                                subSubjectId: "U3ViamVjdDoxNA==",
                                subjectDescription: "",
                                notes: "",
                                startTime: "11:00",
                                endTime: "12:00",
                                theme: "Dit is het thema",
                                purpose: "",
                                delete: false
                            }
                        ]
                    }
                ]
            },
            schedule: {
                grade: { id: "R3JhZGU6Mg==", grade: 2, __typename: "Grade" },
                groupSchedule: { group: {} },
                scheduleRows: []
            },
            scheduleTemplate: {
                id: "U2NoZWR1bGVUZW1wbGF0ZToxMTU2",
                grade: { id: "R3JhZGU6Mg==", grade: 2, __typename: "Grade" },
                group: {
                    id: "R3JvdXA6MTYw",
                    name: "1/2B",
                    grades: [2, 3],
                    __typename: "Group"
                },
                scheduleTemplateRows: [
                    {
                        index: 0,
                        id: "U2NoZWR1bGVUZW1wbGF0ZVJvdzo4MQ==",
                        startTime: "13:00",
                        endTime: "14:00",
                        subjectId: "U3ViamVjdDox",
                        subjectDescription: "",
                        notes: "sadadasd",
                        delete: false
                    },
                    {
                        index: 1,
                        id: "U2NoZWR1bGVUZW1wbGF0ZVJvdzo4Mg==",
                        startTime: "14:00",
                        endTime: "15:00",
                        subjectId: "U3ViamVjdDox",
                        subjectDescription: "",
                        notes: "asdadadada",
                        delete: false
                    }
                ],
                weekday: "A_0",
                modified: "2023-05-08T20:21:09+00:00",
                __typename: "ScheduleTemplate",
                grades: [2]
            }
        };
    },
    computed: {
        lastNotDeleted() {
            return this.formValues.scheduleRows
                .slice()
                .reverse()
                .find(x => !x.delete);
        }
    },
    methods: {
        changeSchedule({ moved }) {
            if (moved) {
                if (
                    moved.newIndex ===
                    this.formValues.scheduleRows.length - 1
                ) {
                    const row = this.formValues.scheduleRows[
                        moved.newIndex - 1
                    ];
                    moved.element.startTime = row.endTime;
                    moved.element.endTime = row.endTime;
                } else {
                    const row = this.formValues.scheduleRows[
                        moved.newIndex + 1
                    ];
                    moved.element.startTime = row.startTime;
                    moved.element.endTime = row.startTime;
                }
            }
            this.updateEndTimes();
        },
        addScheduleRow() {
            const rows = this.formValues.scheduleRows;
            this.formValues.scheduleRows.push({
                ...this.emptyScheduleRow,
                startTime: rows.length ? rows[rows.length - 1].endTime : "",
                index: this.formValues.scheduleRows.length
            });
        },
        updateEndTimes() {
            this.formValues.scheduleRows
                .filter(x => !x.delete)
                .forEach((row, index, rows) => {
                    if (rows.length - 1 !== index) {
                        row.endTime = rows[index + 1].startTime;
                    }
                });
        }
    }
};
</script>
