<template>
    <header class="ds__header">
        <slot />
    </header>
</template>

<script>
export default {
    name: "DsHeader"
};
</script>

<style lang="sass"></style>
