<template>
    <section class="ds__section">
        <slot />
    </section>
</template>

<script>
export default {
    name: "DsSection"
};
</script>

<style lang="sass"></style>
